<template>
      <div class="contract-content">
        <el-table :data="mpaTransactionData" border :header-cell-style="HEADER_CELL_STYLE" :cell-style="CELL_STYLE" width="50%">
          <el-table-column label="Status" width="160px"  show-overflow-tooltip>
            <template v-slot="scope">
              {{scope.row.isSuccessful ? 'Successful' : 'Unsuccessful'}}
            </template>
          </el-table-column>
          <el-table-column label="Error Message" min-width="240px" show-overflow-tooltip>
            <template v-slot="scope">
              {{scope.row.errorMessage ? scope.row.errorMessage : '-'}}
            </template>
          </el-table-column>
          <el-table-column label="Date and Time" width="180px" show-overflow-tooltip>
            <template v-slot="scope">
              {{scope.row.updatedAt ? $moment(scope.row.updatedAt).format("YYYY-MM-DD HH:mm:ss") : '-'}}
            </template>
          </el-table-column>
          <el-table-column label="Via SgTradex" width="180px" show-overflow-tooltip>
            <template v-slot="scope">
              {{scope.row.sgTradexPushRequest ? 'YES' : 'NO' }}
            </template>
          </el-table-column>
        </el-table>
      </div>
</template>

<script>
import { HEADER_CELL_STYLE, CELL_STYLE } from '@/constants.js'
export default {
  name: 'MpaTransactionInformation',
  props: {
    mpaTransactionData: Array
  },
  data () {
    return {
      HEADER_CELL_STYLE,
      CELL_STYLE
    }
  },
  methods: {}
}
</script>

<style lang="scss" scoped>

</style>
