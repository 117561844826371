<template>
	<el-select
    :filter-method="(val) => handleSearchMpaReceivingVessels(val)"
    filterable
    popper-class="custom-ship-selector"
    v-model="vesselSearchKey"
    style="width: 100%"
    :disabled="disabled"
    :loading="searchLoading"
    @visible-change="searchVesselvisibleChange"
    @change="$emit('handleSelectVessel',$event)"
    placeholder="Select"
  >
    <span
      style=" display: inline-block; width: 200px; font-size: 14px; font-weight: 500; margin-left: 20px; margin-bottom: 10px; " >Name</span
    >
    <span
      style=" display: inline-block;  width: 100px; font-size: 14px; font-weight: 500; " >IMO</span
    >
    <span style="display: inline-block; font-size: 14px; font-weight: 500"
      >Licence</span
    >
    <el-option
      v-for="item in options"
      :key="item.id"
      :label="item.shipName"
      :value="item.id"
    >
      <span style="display: inline-block; width: 200px; font-size: 12px"
        >{{ item.shipName }}
      </span>
      <span style="display: inline-block; width: 100px; font-size: 12px">{{
        item.imo
      }}</span>
      <span style="display: inline-block; font-size: 12px">{{
        item.licenceNo
      }}</span>
    </el-option>
  </el-select>
</template>

<script>
import {   mapState } from "vuex";
import { getReceivingVesselListBySearch, } from "@/services/modules/comm.js";
export default {
  name: 'SearchVesselSelector',
  props: {
    disabled:Boolean,
    searchedVesselList:Array,
    cashType:String,
    schedulFormData:Object,
  },
  data () {
    return {
      vesselSearchKey:'',
      searchLoading:true,
      options:[]
    }
  },

  computed: {
    ...mapState({
      currentCompanySettings: "currentCompanySettings",
    }),

  },
  methods: {
    searchVesselvisibleChange(isVisit) {
      if (isVisit) {
        this.handleSearchMpaReceivingVessels("");
      }
    },
    async handleSearchMpaReceivingVessels(searchKey) {
      const companyId =
        this.currentCompanySettings.canFilterSearchByCompanyId &&
        this.cashType == "non-cash"
          ? this.schedulFormData.buyerSysOrganizationId
          : null;
      try {
        const res = await getReceivingVesselListBySearch(searchKey, companyId);
        if (res?.code === 1000) {
          this.options = res.data.records?.length > 0 ? res.data.records : [];
          this.$emit('update:searchedVesselList',this.options)
          this.searchLoading =false
        }
      } catch (error) {}
    },


  }
}
</script>
